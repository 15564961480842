import * as React from "react"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const FaqItems = [
    {
        question: "Kik a ReX Toborzók?",
        answer: 'A ReX Toborzók szakmailag független, profi toborzási szakértők, akik lehetnek szabadúszó vállalkozók vagy akár toborzóirodák is. Hogy partnerünkké válhassanak, a ReX Toborzók alapos felvételi, tesztelési és képzési folyamaton mennek keresztül. Miután aláírták az együttműködési megállapodást, és sikeres vizsgát tettek adatkezelési és GDPR témakörből, a ReX Toborzók feladata a toborzási folyamatok támogatása a ReX crowdstaffing platformon.',
    },
    {
        question: "Ki a ReX Partner?",
        answer: 'A ReX Partner és a ReX Toborzó bizonyos esetekben felcserélhető fogalmak; az együttműködési megállapodásokban és a rendszerben azonban megkülönböztetjük őket: a megállapodást aláíró személyt vagy vállalkozást ReX Partnernek, míg a toborzási folyamatban ténylegesen közreműködő megbízottat ReX Toborzónak nevezzük.',
    },
    {
        question: "Ki a tanácsadó?",
        answer: 'A tanácsadó az Antal/Enloyd azon munkavállalója, aki az ügyféllel kötött megállapodás értelmében a jelölt kiválasztásáért felelős.',
    },
    {
        question: "Ki a ReX Vezető?",
        answer: 'A REX Vezető az a személy, aki egy adott szakterület projektjeit irányítja. Az ügyfél részéről a ReX Vezető gondoskodik a kommunikációról és az adott projekt megvalósításához legmegfelelőbb ReX Toborzók kiválasztásáról. A platformon támogatja a ReX toborzókat, és kizárólag olyan projektek megvalósításával bízza meg őket, amely a szakmai tapasztalataiknak megfelel.',
    },
    {
        question: "Ki a Kiemelt Ügyfélkapcsolati Menedzser?",
        answer: 'A Kiemelt Ügyfélkapcsolati Menedzser (vagy KAM) egy adott terület toborzásának szakértője, aki a megbízáson belül az ügyfél és a jelölt kiszolgálására kijelölt személy; kiegészítő díj ellenében ellenőrzi az értesítéseket, illetve konzultációs, tanácsadói és koordinációs szolgáltatásokat nyújt az ügyfél számára.',
    },
    {
        question: "Mi a Crowdstaffing (közösségi toborzás) és hogyan működik?",
        answer: 'A közösség erejét arra használjuk, hogy egy személy vagy egy vállalat igényeit hatékonyan kielégítsük. Mivel az ideális jelölteket egyidejűleg több jólképzett szakember keresi földrajzilag kiterjedt területen különböző eszközök és adatbázisok segítségével, így gyorsan és hatékonyan lehet a toborzási folyamatot elvégezni.',
    },
    {
        question: "Hogyan lehet toborzást megrendelni?",
        answer: 'Amint megkapjuk a betöltendő munkakör leírását, elkészítjük és megküldjük a vonatkozó árajánlatot. Ha az ajánlatkérő az ajánlatot elfogadja, a portálon keresztül megküldjük részére a megrendelést, amely a felek elektronikus aláírásával válik érvényessé.',
    },
    {
        question: "Kötelező regisztrálni a platformra?",
        answer: 'Nem. Az ajánlatkéréseket, a betöltendő munkakör leírását és az egyéb adatokat is el lehet küldeni a portálon található űrlap kitöltésével vagy a közvetlenül a karolina@recruitmentexchange.pl e-mail címre.',
    },
    {
        question: "Pontosan mi az, amit az ügyfél kap?",
        answer: 'Az ügyfél megkapja az előválogatott jelöltek profilját, amely tartalmazza a jelölt elérhetőségeit, pénzügyi igényét, a munkakezdés idejét (felmondási idő tartamát), a jelölt által preferált együttműködési formát és a tartózkodási helyet, valamint a toborzó szöveges ajánlását a jelölt önéletrajzával együtt. Ebben a szakaszban a kiajánlott jelöltek nyitottak arra, hogy tárgyaljanak az adott pozícióra vonatkozó ajánlatról.',
    },
    {
        question: "Mennyibe kerül a szolgáltatás? Mikor kell a szolgáltatás díját megfizetni?",
        answer: 'A szolgáltatás díja nem függ a jelölt javadalmazásától. Az irányárak a weboldalon ITT tekinthetőek meg, amelyek az aktuális piaci helyzet, a jelöltek elérhetősége, valamint az elvárások és a foglalkoztatási lehetőségek alapján módosulhatnak. A számlát a jelölt munkába állásának napján (munkaviszonyának kezdetén), 14 napos fizetési határidővel állítjuk ki.',
    },
    
];

const Faq = () => {
  return (
      <div className="overflow-hidden">
    <section id="faq" className="faq pt-0 pb-24 bg-gradient-to-br from-purple to-teal">
        <div className="max-w-7xl mx-auto">
            <h2 className="text-3xl text-center text-white font-bold mb-20">Gyakran ismételt kérdések</h2>
        <Accordion allowZeroExpanded="true">
            {FaqItems.map((faqItem) => (
        <AccordionItem className="mb-10">
          <AccordionItemHeading className="bg-white px-8 py-5 pt-6 text-purple shadow-xl relative z-10">
            <AccordionItemButton className="faq-button relative">
              {faqItem.question}
                <div className="arrow border-b-purple border-l-purple w-3 h-3 absolute right-0 top-0 border-[0.35rem] border-r-transparent border-t-transparent -rotate-45 transition-all"></div>
            </AccordionItemButton> 
          </AccordionItemHeading>
          <AccordionItemPanel className="bg-white px-8 py-8  text-dark-gray text-sm">
            {faqItem.answer}
          </AccordionItemPanel>
        </AccordionItem>
            ))}
            <div className="mb-10">
                <div className="bg-white px-8 py-5 pt-6 text-purple shadow-xl relative z-10">
                <div className="relative flex flex-col md:flex-row justify-between items-center">
                        <h3 className="text-2xl basis-1 mr-4">Kérdésed&nbsp;van?</h3>
                        <div className="bg-gradient-to-r from-purple to-teal basis-full h-[2px] my-4"></div>
                        <a href="mailto:robert.nawrot@recruitmentexchange.pl" className="ml-4 basis-1 bg-teal text-dark-gray text-sm font-bold  px-24 pt-3  py-2 rounded-b-3xl rounded-tr-3xl shadow-md shadow-teal inline text-center hover:bg-purple hover:text-white hover:shadow-purple">
                            Írj&nbsp;nekünk!
                        </a>
                    </div>
                </div>
            </div>

        
      </Accordion>
      </div>
    </section>
      </div>
  )}

  export default Faq