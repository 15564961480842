import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import LogoRex from "../../images/logo-rex.svg"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Examples = () => {
    let settings = {
        dots: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        speed: 600,
        slidesToShow: 3,
        slidesToScroll: 1,
          responsive: [
              {
                  breakpoint: 800,
                  settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      infinite: true,
                  },
              },
              ]
    };


  return (
    <section  className="examples relative pt-52 pb-10 bg-center -mt-40" id="achievements">
        <div className="absolute bg-light-pink w-full left-0 h-1/2 bottom-0">

        </div>
        <div className="max-w-7xl mx-auto mt-40 md:mt-20 ">
            <h2 className="text-white font-bold text-3xl mb-12" >Teljesített<br/> projektek  </h2>
            <p className="text-white mb-0 "  >A ReX Toborzókkal még a legbonyolultabb toborzási feladatokat is sikeresen végre tudjuk hajtani. Közösségünk a teljes gazdaságot lefedő, specializált szakértőkből áll. Megtaláljuk leendő munkatársait, akár 1, akár 10, akár 100 fővel kívánja csapatát bővíteni.</p>
            <Slider {...settings} className="relative -mx-10" >

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/monika-k.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Junior számviteli munkatárs</h3>
                        <p className="text-xs mb-6">A havi pénzügyi beszámolók elkészítése, adatszolgáltatás a társadalombiztosítási szervek (ZUS), a Központi Statisztikai Hivatallal (GUS) és az adóhivatal felé, bérszámfejtés és bérkifizetések lebonyolítása, a dokumentáció archiválása, az alábbi programok ismerete: Płatnik és Progmann – Kadry, Przelewy.</p>
                        <p className="text-xs font-bold">Erre a pozícióra jellemzően Poznan, Wroclaw és Varsó területéről toborzunk jelölteket. A szolgáltatás díja ~ 7 000 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Monika – Junior számviteli munkatárs – Toborzási díj –  7 000 PLN</h3>
                        <p className="text-sm">A teljes körű és egyszerűsített számviteli eljárásokat alkalmazó társaságok számviteli és személyzeti folyamatainak ellátása.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/anna.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Anna, CS szlovén nyelvtudással</h3>
                        <p className="text-xs mb-6">A webáruházba érkező megrendeléseik és panaszok teljes körű kezelése. Szlovák ügyfelek telefonos és e-mailes támogatása, az értékesített termékkel kapcsolatos tanácsadás.</p>
                        <p className="text-xs font-bold">Erre a pozícióra jellemzően Varsóban toborzunk jelölteket. A szolgáltatás díja ~ 8 500 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Anna, CS szlovén nyelvtudással – Toborzási díj –  8 500 PLN</h3>
                        <p className="text-sm">Az ügyfél termékét megvásárló ügyfelekkel való kommunikáció e-mailben és telefonon.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/urszula.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Személyzeti tanácsadó</h3>
                        <p className="text-xs mb-6">Egyéb elnevezéssel: HR szakértő. A teljes toborzási folyamat lebonyolítása, támogatás munkajogi kérdésekben.</p>
                        <p className="text-xs font-bold">Erre a pozícióra jellemzően Varsóban toborzunk jelölteket. A szolgáltatás díja ~ 17 000 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Urszula – Személyzeti tanácsadó – Toborzási díj: 17 000 PLN.</h3>
                        <p className="text-sm">A munkavállalók támogatása adminisztratív és HR-ügyekben. </p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/adam.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Kereskedelmi értékesítő</h3>
                        <p className="text-xs mb-6">A kitűzött célok megvalósítása, a meglévő ügyfélkapcsolatok ápolása, új ügyfelek felkutatása.</p>
                        <p className="text-xs font-bold">Erre a pozícióra Lengyelország minden részéről toborzunk jelölteket. A szolgáltatás díja ~ 10 000 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Adam – Kereskedelmi értékesítő – Toborzási díj: 10 000 PLN.</h3>
                        <p className="text-sm">A vállalat szolgáltatásainak értékesítése és képviselete az ügyfelekkel való kapcsolattartás során.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/ewa.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">CS német nyelvtudással</h3>
                        <p className="text-xs mb-6">A megrendelések adatainak rögzítése az SAP-ban, az ügyfelek aktuális problémáinak megoldása, az ügyfelek tájékoztatása megrendelésük aktuális állapotáról.</p>
                        <p className="text-xs font-bold">Erre a pozícióra Lengyelország minden részéről toborzunk jelölteket. A szolgáltatás díja ~ 8 000 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Ewa, CS német nyelvtudással – Toborzási díj –  8 000 PLN</h3>
                        <p className="text-sm">Vállalati ügyfelek megrendeléseihez kapcsolódó telefonos és e-mailes ügyintézés.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/tomek.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">Ügyfél minőségbiztosítási szakértő</h3>
                        <p className="text-xs mb-6">Az ügyféligények szervezeten belüli megvalósításának felügyelete. Ügyfélpanaszok kezelése (8D jelentések + az intézkedések nyomon követése). Minőségbiztosítási elemzések és jelentések készítése. PPAP előkészítése a sorozatgyártásban történő változtatásokhoz.</p>
                        <p className="text-xs font-bold">Erre a pozícióra Lengyelország minden részéről toborzunk jelölteket. A szolgáltatás díja ~ 12 000 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Tomek, Ügyfél minőségbiztosítási szakértő – Toborzási díj – 12 000 PLN</h3>
                        <p className="text-sm">Az ügyfélhez beérkező panaszok rendezésének első védelmi vonala.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

            <div className="mt-32 px-10 group hover:scale-105 transition-all">
                <div className="example mb-32  object-fill relative aspect-[3/4] border-[12px] border-white">
                    <StaticImage quality={100} className="shadow-xl absolute inset-0 " src={'../../images/examples/monika-c.png'}/>
                    <div className="absolute flex flex-col justify-end inset-0 bg-purple bg-opacity-80 text-white px-8 py-7 w-full opacity-0 group-hover:opacity-100 transition-all">
                        <h3 className="mb-4 font-bold text-md">C# fejlesztő</h3>
                        <p className="text-xs mb-6">Az adatok automatikus frissítésére szolgáló szoftverek fejlesztése.
                            Szoftverfrissítések bevezetése az üzleti igényeknek megfelelően.
                            Kapcsolattartás az üzleti oldallal</p>
                        <p className="text-xs font-bold">Erre a pozícióra Lengyelország minden részéről toborzunk jelölteket. A szolgáltatás díja ~ 12 000 PLN.</p>
                    </div>
                    <div className="absolute bottom-0 bg-purple bg-opacity-80 w-full text-white px-8 py-7 group-hover:hidden">
                        <h3 className="mb-4 font-bold">Monika – C# fejlesztő – Toborzási díj – 25 000 PLN</h3>
                        <p className="text-sm">A mechanikai és hidraulikai terhelések számítási programjának kidolgozása a fúrási átmérő figyelembevételével.</p>
                    </div>
                    <StaticImage className="absolute right-10 -top-14" src={'../../images/accent.png'} />
                </div>
            </div>

                
            </Slider>   
           
        </div>
    </section>    
  )
}

export default Examples