import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Efektywna from '../../images/about-icons/efektywna.svg'
import Tansza from '../../images/about-icons/tansza.svg'
// import Szybsza from '../../images/about-icons/szybsza.svg'
import Nieograniczona from '../../images/about-icons/nieograniczona.svg'


const About = () => {
  return (
    <section id="about-rex" className="pb-40">
        <div className="max-w-7xl mx-auto grid md:grid-cols-2 pt-20 ">
            <div className="z-20 relative ">
                <h2 className="text-4xl font-bold pb-12">About <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">ReX</span></h2>
                <p className="text-gray-900 font-light mb-14">
                A Rex egy olyan innovatív közösségi toborzási (crowdstaffing) platform, amely hidat képez az ügyfelek, a toborzók és a jelöltek között. A ReX Toborzókkal még a legspeciálisabb toborzási feladatokat is sikeresen végre tudjuk hajtani. Folyamatainkat ügyfeleink igényei szerint alakítjuk, és megmutatjuk, hogy a toborzás egyszerű is lehet.
                </p>
                
                <a href="#top" className="bg-teal px-10 py-3 rounded-b-3xl rounded-tr-3xl shadow-md shadow-teal inline-block text-center hover:bg-purple hover:text-white hover:shadow-purple mb-20">Toborzás indítása</a>

                <h3 id="recruitment-with-rex" className="text-3xl font-bold pb-12 text-dark-gray"><span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">ReX</span> – a toborzás új korszaka</h3>
                <div className="grid md:grid-cols-2 gap-10">
                    <div className="relative">
                        <div className="flex items-end mb-5">
                        <StaticImage className="inline h-12 w-12 mr-5" src={'../../images/about-icons/efektywna.svg'} alt="" />
                        <h3 className="inline font-bold leading-tight">Eredményes</h3>
                        </div>
                        <p className="mb-1 font-light text-dark-gray text-[14px]">A projektek megvalósításában olyan toborzók vesznek részt, akik nem csak az adott szektort, de a földrajzi területet is jól ismerik, így képesek megtalálni a legideálisabb jelölteket.</p>
                    </div>
                    <div className="relative">
                        <div className="flex items-end mb-5">
                        <StaticImage className="inline h-12 w-12 mr-5" src={'../../images/about-icons/tansza.svg'} alt="" />
                        <h3 className="inline font-bold leading-tight">Olcsóbb</h3>
                        </div>
                        <p className="mb-1 font-light text-dark-gray text-[14px]">A toborzás költsége átlagosan 50%-kal alacsonyabb, az elszámolási modell pedig kifejezetten előnyös az ügyfél számára, aki csak a felvett jelöltekért fizet.</p>
                    </div>
                    <div className="relative">
                        <div className="flex items-end mb-5">
                        <StaticImage className="inline h-12 w-16 mr-5" src={'../../images/about-icons/szybsza.svg'} alt="" />
                        <h3 className="inline font-bold leading-tight">Gyorsabb</h3>
                        </div>
                        <p className="mb-1 font-light text-dark-gray text-[14px]">A több száz toborzóval kialakított együttműködés a garancia arra, hogy azonnal lépünk, és az első jelölteket az ügyfél a lehető leggyorsabban megismerheti.</p>
                    </div>
                    <div className="relative">
                        <div className="flex items-end mb-5">
                        <StaticImage className="inline h-12 w-12 mr-5" src={'../../images/about-icons/nieograniczona.svg'} alt="" />
                        <h3 className="inline font-bold leading-tight">Határtalan</h3>
                        </div>
                        <p className="mb-1 font-light text-dark-gray text-[14px]">A ReX Toborzók több országban, számos különböző helyszínen dolgoznak, ami azt jelenti, hogy széleskörű hozzáféréssel rendelkeznek a jelöltekhez</p>
                    </div>
                </div>    
            </div>
            <div className="flex justify-center items-center relative z-20 m">
                <div className="mt-20 shadow-3xl  bg-white text-center p-10 md:m-20 relative z-10">
                    <StaticImage className="w-36 mb-12" src={'../../images/logo-antal-color.svg'} alt="" />
                    <h3 className="text-purple font-bold uppercase text-lg mb-5">ANTAL/ENLOYD MEGOLDÁS</h3>
                    <p className="text-gray text-lg">A Rex platform az Antal/Enloyd márka erejére támaszkodva született. A több mint 25 éves szakmai tapasztalattal és a legjobb szakemberekkel rendelkező z Antal/Enloyd garancia a kiváló minőségre.</p>
                </div>
            </div>
        </div>
        
    </section>
  )
}

export default About