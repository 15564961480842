import React, { Component } from "react"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import ReactTooltip from 'react-tooltip';

const API_PATH = "/api-mail-top-hu.php"
const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Content of this field is too short")
    .max(50, "Content of this field is too long")
    .required("Kötelező mező"),
  email: Yup.string()
    .min(2, "Content of this field is too short")
    .max(50, "Content of this field is too long")
    .email("Not a proper e-mail address format")
    .required("Kötelező mező"),
  phone: Yup.string()
    .min(5, "Content of this field is too short")
    .max(15, "Content of this field is too long"),
  privacy: Yup.bool().oneOf([true], "Acceptance required."),
  privacy2: Yup.bool().oneOf([true], "Acceptance required."),
})

export default class ContactFormTop extends Component {


  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      position: "",
      region: "",
      sallary: "",
      description: "",
      mailSent: false,
      error: null,
      privacy: false,
      privacy2: false,
    }
  }

  handleFormSubmit = e => {
    // e.preventDefault()
    axios({
      method: "POST",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: e,
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent,
          error: result.data.message,
        })
        window.fbq('track', 'Lead');
        window.dataLayer.push({
          event: "conversion",
        })
      })
      .catch(error => this.setState({ error: error.message }))
  }

  

  render() {
    if (this.state.mailSent === false) {
      return (
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            message: "",
            position: "",
            region: "",
            sallary: "",
            description: "",
            privacy: false,
            privacy2: false,
          }}
          validationSchema={ValidationSchema}
          onSubmit={values => {
            this.handleFormSubmit(values)
          }}
        >
          {({ isSubmitting }) => (
            <Form className="">
              <h3 className="text-2xl font-bold text-dark-gray"> Hogyan működik a <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text"> ReX</span>?</h3>
              <div className={this.props.formState ? "grid grid-cols-1 gap-10" : "grid md:grid-cols-2 md:gap-20"}>
                <div>
                  <div className="mb-10">
                    
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="name" placeholder="Kereszt- és vezetéknév"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="name"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="email" placeholder="E-mail cím*"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="email"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="phone" placeholder="Telefonszám"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="phone"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none resize-none" type="text" as="textarea" name="message" placeholder="Üzenet" rows="4"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="message"
                          component="span"
                        />
                    </div>
                    </div>
                </div>
                <div id="hidden-fields" className={this.props.formState ? "hidden" : "" }>
                <div className="mb-10">
                    
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="position" placeholder="Beosztás"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="position"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="region" placeholder="A toborzás régiója"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="region"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none " type="text" name="sallary" placeholder="Havi bruttó fizetés"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="sallary"
                          component="span"
                        />
                    </div>
                    <div className="">
                      <Field className="border-b-2 border-purple mt-4 w-full pt-3 pb-1 text-dark-gray placeholder:text-light-gray placeholder:text-sm outline-none resize-none" type="text" as="textarea" name="description" placeholder="Description" rows="4"/>
                      <ErrorMessage
                          className="error-msg text-xs"
                          name="description"
                          component="span"
                        />
                    </div>
                    </div>
                    
                </div>

                <div className="privacy-policies -mt-10">
                    <div>
                      <label
                        className="text-dark-gray relative pl-7 block text-sm mb-4"
                        htmlFor="privacy"
                        data-tip="Ezennel hozzájárulok személyes adataim az Antal sp.z o.o. által a megkeresés kezelésével kapcsolatban történő feldolgozásához, melynek székhelye Varsó (02-566), ul. Puławska 2, mely a KRS cégjegyzékbe 0000825336 számon van bejegyezve. <br> Egyúttal kijelentem, hogy: <br> 1. Tisztában vagyok azzal, hogy adataim megadása önkéntes, és a feldolgozás a beleegyezésem alapján történik. <br> 2. Tisztában vagyok vele, hogy jogom van bármikor visszavonni a beleegyezésemet. <br> 3. Tisztában vagyok azzal, hogy személyes adataim a megkeresés - amelyhez hozzájárulásomat adtam - kezelésével kapcsolatos szolgáltatás végéig kerülnek feldolgozásra, vagy a hozzájárulás visszavonásáig, azzal a feltétellel, hogy a megkeresés kezelésének befejezése vagy a hozzájárulás visszavonása után az adatok az adminisztrátor és az érintett adminisztrátorral szembeni követeléseinek elévülési ideje alatt tovább feldolgozhatók. <br> 4. Tudomásul veszem, hogy a hozzájárulás visszavonható azáltal, hogy e-mailt küldök a következő címre: mail@antal.pl."
                      >
                        Hozzájárulás a személyes adatok kezeléséhez  <span className="ml-3 bg-neutral-300 h-4 w-4 rounded-full inline-flex justify-center items-center text-white font-serif">i</span>
                      <Field
                        type="checkbox"
                        id="privacy"
                        name="privacy"
                        className="absolute left-0 top-0"
                      />
                        <ErrorMessage
                          className="error-msg text-xs"
                          name="privacy"
                          component="span"
                        />
                      </label>
                      <ReactTooltip arrowColor={"#fff"} html={true} className="policy-tooltip"/>
                    </div>
                    <div>
                      <label
                        className="text-dark-gray relative pl-7 block text-sm mb-5"
                        htmlFor="privacy2"
                        data-tip="Hozzájárulok az Antal sp. z o. o.-tól származó kereskedelmi információk fogadásához a 2002. július 18-i a szolgáltatások elektronikus úton történő nyújtásáról szóló törvény (2013 évi Lengyel Közlöny., 1422. Poz.) értelmében, valamint az Antal sp. z o. o. általi végberendezések és automatikus hívórendszerek használatára a 2004. július 16-i távközlési törvénynek (2014 évi Lengyel Közlöny., 243. Poz.) megfelelően. Kijelentem, hogy tisztában vagyok azzal, hogy a hozzájárulás megadása önkéntes. Tudomásul veszem, hogy a hozzájárulás visszavonható azáltal, hogy e-mailt küldök a következő címre: mail@antal.pl."
                      >
                        Hozzájárulás marketing célú üzenetek küldéséhez <span className="ml-3 bg-neutral-300 h-4 w-4 rounded-full inline-flex justify-center items-center text-white font-serif">i</span>
                      <Field
                        type="checkbox"
                        id="privacy2"
                        name="privacy2"
                        className="absolute left-0 top-0"
                      />
                        <ErrorMessage
                          className="error-msg text-xs"
                          name="privacy2"
                          component="span"
                        />
                      </label>
                    </div>
                    </div>
              </div>
              
              <div className="flex justify-end py-10">
              <button
                className="bg-teal text-dark-gray text-sm font-bold px-10 pt-4  py-3 rounded-b-3xl rounded-tr-3xl shadow-md shadow-teal inline text-center hover:bg-purple hover:text-white hover:shadow-purple"
                type="submit"
                disabled={isSubmitting}
              >
                Küldés <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="ml-1 w-3 h-3 inline-block">
  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" /> </svg>
              </button>
              </div>
              
            </Form>
          )}
        </Formik>
      )
    } else {
      return (
        <form className="py-24 text-center text-dark-gray">
          <h3 className="text-3xl mb-5">Thank you!</h3>
          <p className="text-xl mb-12">Your message has been sent.</p>
        </form>
      )
    }
  }
}
