import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HowWeWork = () => {
  return (
    <section id="how-do-we-operate" className="relative overflow-visible object-none py-24 ">
        <div className="max-w-7xl mx-auto">
            <h2 className="text-center text-3xl font-bold text-dark-gray mb-20">A toborzási <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">folyamat</span>?</h2>
            <div className="grid lg:grid-cols-3 gap-20 xl:-mx-20">
                <div className="how-we-work-element text-center group">
                    <div className="text-[120px]  block group-hover:text-teal group-hover:scale-125 origin-center transition-all">1</div>
                    <h3 className=" font-bold mb-8 group-hover:text-purple">Az ügyfél elküldi az aktuális megbízást</h3>
                    <p className="font-light text-dark-gray text-md">Nekünk fontos az ügyfeleink ideje, így a megbízáshoz nem szükséges regisztráció Elegendő a betöltendő pozíció leírása, és néhány egyszerű kérdés megválaszolása.</p>
                </div>
                <div className="how-we-work-element text-center group">
                    <div className="text-[120px]  block group-hover:text-teal group-hover:scale-125 origin-center transition-all">2</div>
                    <h3 className=" font-bold mb-8 group-hover:text-purple">Azonnal megkezdjük a megbízás teljesítését és kiválasztjuk a legmegfelelőbb toborzókat</h3>
                    <p className="font-light text-dark-gray text-md">A megbízás teljesítésében olyan kiváló ReX Toborzók vesznek részt, akik nem csak az adott szektort ismerik, de a földrajzi sajátosságokkal is tisztában vannak, így valóban megfelelő jelölteket tudnak ajánlani. A toborzók haladéktalanul megkezdik a jelöltek felkutatását.</p>
                </div>
                <div className="how-we-work-element text-center group">
                    <div className="text-[120px]  block group-hover:text-teal group-hover:scale-125 origin-center transition-all">3</div>
                    <h3 className=" font-bold mb-8 group-hover:text-purple">Kiválasztjuk a jelölteket és lezárjuk a projektet</h3>
                    <p className="font-light text-dark-gray text-md">Ügyfelünk néhány napon belül megkapja az első önéletrajzokat a megfelelő jelöltektől. Nálunk az ügyfél csak akkor fizet, ha az ajánlott jelölttel a munkaszerződést aláírja. Működési feltételeink átláthatóak, nincsenek rejtett költségek.</p>
                </div>
            </div>
            
           
        </div>
        
    </section>
  )
}

export default HowWeWork