import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import LinkedIn from "../../images/linkedin.svg";

const Leaders = () => {
    var settings = {
        dots: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 1300,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ]
    };
  return (
    <div id="team-rex" className="relative overflow-visible object-none py-28 ">
        <div className="max-w-7xl mx-auto">
        <h2 className="text-center text-3xl font-bold text-dark-gray mb-14 relative ">Get to know our <span className="bg-gradient-to-br from-purple to-teal text-transparent bg-clip-text">ReX</span> Leaders</h2>
            </div>
            <div className="mx-10">
            <div className="md:flex justify-center space-x-14 mb-20">
                 <a target="blank" href="https://www.linkedin.com/in/maja-wilczewska-45ba6a9b" className="team-member text-center basis-1/4">
                    <div className="image-title-wrap relative text-center object-contain">
                        <StaticImage quality={70}  className="m-10 rounded-full border-[7px] border-purple" src={'../../images/leaders/new/maja-wilczewska.jpg'} />
                        <div className="relative z-10 text-dark-gray">
                            <h3 className="text-sm font-bold mb-3">Maja Wilczewska</h3>
                            </div>
                    </div>
                    <div className="text-dark-gray text-sm text-center"><p className="mb-5">ReX Leader</p><LinkedIn className="inline-block w-3 h-3"/></div>
                    
                </a>
                <a target="blank" href="https://www.linkedin.com/in/szymonjarocki" className="team-member text-center basis-1/4">
                    <div className="image-title-wrap relative text-center object-contain">
                        <StaticImage quality={70}  className="m-10 rounded-full border-[7px] border-purple " src={'../../images/leaders/new/szymon-jarocki.jpg'} />
                        <div className="relative z-10 text-dark-gray">
                            <h3 className="text-sm font-bold mb-3">Szymon Jarocki</h3>
                           </div>
                    </div>
                    <div className="text-dark-gray text-sm text-center"><p className="mb-5">ReX Leader</p><LinkedIn className="inline-block w-3 h-3"/></div>
                    
                </a>
               
                
                </div>  

                <div className="md:flex justify-center space-x-14 mb-20">
                <a target="blank" href="https://www.linkedin.com/in/ilona-wrobel" className="team-member text-center basis-1/4">
                    <div className="image-title-wrap relative text-center object-contain">
                        <StaticImage quality={70}  className="m-10 rounded-full border-[7px] border-purple " src={'../../images/leaders/new/ilona-wrobel.jpg'} />
                        <div className="relative z-10 text-dark-gray">
                            <h3 className="text-sm font-bold mb-3">Ilona Wróbel </h3>
                           </div>
                    </div>
                    <div className="text-dark-gray text-sm text-center"><p className="mb-5">Antal ReX Leader</p><LinkedIn className="inline-block w-3 h-3"/></div>
                    
                </a>
                <a target="blank" href="https://www.linkedin.com/in/magdapietrzak" className="team-member text-center basis-1/4">
                    <div className="image-title-wrap relative text-center object-contain">
                        <StaticImage quality={70}  className="m-10 rounded-full border-[7px] border-purple" src={'../../images/leaders/new/magdalena-pietrzak.jpg'} />
                        <div className="relative z-10 text-dark-gray">
                            <h3 className="text-sm font-bold mb-3">Magdalena Pietrzak </h3>
                           </div>
                    </div>
                    <div className="text-dark-gray text-sm text-center"><p className="mb-5">Antal ReX Leader</p><LinkedIn className="inline-block w-3 h-3"/></div>
                    
                </a>
                <a target="blank" href="https://www.linkedin.com/in/sylwia-moskwa" className="team-member text-center basis-1/4">
                    <div className="image-title-wrap relative text-center object-contain">
                        <StaticImage quality={70}  className="m-10 rounded-full border-[7px] border-purple" src={'../../images/leaders/new/sylwia-moskwa.jpg'} />
                        <div className="relative z-10 text-dark-gray">
                            <h3 className="text-sm font-bold mb-3">Sylwia Moskwa </h3>
                           </div>
                    </div>
                    <div className="text-dark-gray text-sm text-center"><p className="mb-5">Antal ReX Leader</p><LinkedIn className="inline-block w-3 h-3"/></div>
                    
                </a>
                <a target="blank" href="https://www.linkedin.com/in/szilvia-t%C3%B3th-0b638572" className="team-member text-center basis-1/4">
                    <div className="image-title-wrap relative text-center object-contain">
                        <StaticImage quality={70}  className="m-10 rounded-full border-[7px] border-purple " src={'../../images/leaders/new/szilvia-toth.jpg'} />
                        <div className="relative z-10 text-dark-gray">
                            <h3 className="text-sm font-bold mb-3">Szilvia Tóth </h3>
                           </div>
                    </div>
                    <div className="text-dark-gray text-sm text-center"><p className="mb-5">Enloyd ReX Leader</p><LinkedIn className="inline-block w-3 h-3"/></div>
                    
                </a>

                </div>  

                <div className="md:flex justify-center space-x-14 mb-20">
                 <a target="blank" href="https://www.linkedin.com/in/sebastian-sala-68b57b3" className="team-member text-center basis-1/4">
                    <div className="image-title-wrap relative text-center object-contain">
                        <StaticImage quality={70}  className="m-10 rounded-full border-[7px] border-purple " src={'../../images/leaders/new/sebastian-sala.jpg'} />
                        <div className="relative z-10 text-dark-gray">
                            <h3 className="text-sm font-bold mb-3 w-full">Sebastian Sala</h3>
                            </div>
                    </div>
                    <div className="text-dark-gray text-sm text-center"><p className="mb-5">ReX Director</p><LinkedIn className="inline-block w-3 h-3"/></div>
                    
                </a>
                <a target="blank" href="https://www.linkedin.com/in/karolina-korzeniewska-17324558" className="team-member text-center basis-1/4">
                    <div className="image-title-wrap relative text-center object-contain">
                        <StaticImage quality={70}  className="m-10 rounded-full border-[7px] border-purple" src={'../../images/leaders/new/karolina-korzeniewska.jpg'} />
                        <div className="relative z-10 text-dark-gray">
                            <h3 className="text-sm font-bold mb-3">Karolina Korzeniewska  </h3>
                           </div>
                    </div>
                    <div className="text-dark-gray text-sm text-center"><p className="mb-5">ReX Manager</p><LinkedIn className="inline-block w-3 h-3"/></div>
                    
                </a>
               
                
                </div>     
    </div>
     </div>
  )
}

export default Leaders