import * as React from "react"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const TestimonialsArray = [
    {
        content: "“I came to the REX platform for a while but stayed for longer. My adventure has been going on for more than half a year. That’s a lot of time in which I’ve been able to participate in numerous projects from different industries. I’ve had great experience, excellent cooperation and communication with Rex Leaders!",
        author: 'Mateusz Biegała',
        authorBio: ""
    },
    // {
    //     content: "Working with the ReX platform gives me what I expect in a partnership – freedom. The ReX platform suits me because I can choose interesting projects on my own, I can immediately see specific information about the client’s requirements, I get a clear quote for the project, and the invoice is paid on time and without any communication problems. The ReX platform is also right for me because I can choose between industries and recruit in different areas to gain new experiences and add variety to my work. And most of all, because there are real people behind it – ReX Leaders, who are ready to cooperate, open-minded and professional. The ReX platform is for me because no one tells me what time, how long and where to work. It’s intuitive, easy to use and read, and gives me the opportunity to participate in training courses and gain new knowledge. This is what the ReX platform is to me and, above all, the Wonderful People behind it. A job that’s a passion. A passion that’s a job. Highly recommended!",
    //     author: 'Monika Witkowska',
    //     authorBio: ""
    // },
    {
        content: "I’ve been cooperating with the ReX platform since the autumn of 2020. It gives me the opportunity to choose projects that interest me in specific areas. Using my previous experience and skills in this work, I’ve gained many new ones. Thus, the ReX platform provides opportunities for development, which only need to be properly used. In addition, working with a professional team in a unique atmosphere gives me a lot of satisfaction and joy. Highly recommended!",
        author: 'Wojciech Chróściński',
        authorBio: ""
    },
    {
        content: "I recommend cooperation within the ReX Partner project. Transparent terms and conditions, viable projects to close, full support from the Rex Leader, attractive rates and the ability to adjust the hours to other activities; these are the main but not the only advantages of this programme.",
        author: 'Michał Magowski',
        authorBio: ""
    },
    // {
    //     content: "I’ve been working as a recruiter for 6 months. Despite having more than 20 years of extensive experience in HR structures, I had to learn basic tools. Social networks, recruiter software, invaluable training courses and leadership support – all this has proved to be an important basis for work. After these 6 months, it’s become easier and easier for me to reach out to candidates, and to use various tools to get them and meet fantastic people. I hope that the day when I get the salary for the first employment will come soon. Certainly, a considerable difficulty comes from combining a permanent job with freelance work. You need to have a strict daily schedule and, very often, act quickly. In such situations, I would modify some rigorous recruitment procedures. Simply for the sake of supporting the recruiters and giving them a chance to complete their projects. Such measures can definitely improve the quality of work and the company’s reputation. Why, despite not getting paid, don’t I give up on freelancing? – at least for the time being :) It’s invaluable for me to gain some solid experience. Curiosity about the world and people is another important aspect. Creativity in reaching out to a prospective employee has proved to be an invaluable skill. What’s most important to me, however, is the possibility to talk to the candidates, make valuable new contacts and get thanks from the candidates, even if they’re not hired. This is a great sense of satisfaction and attentiveness for the recruiter.",
    //     author: 'Best regards, Eliza ',
    //     authorBio: ""
    // },
    
]
const Testimonials = () => {
    var settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ]
    };
  return (
    <section  className="relative overflow-visible object-none pt-24 z-10">
            <div className="overflow-hidden pb-52">
                <div className="max-w-7xl mx-auto">
            <Slider {...settings} className="testimonials-slider overflow-visible relative -mx-5">
                {TestimonialsArray.map((testimonial) => (
                <div className="px-5">
                <div className="min-h-[420px] single-slide-inner bg-teal text-dark-gray p-10 border-4 border-purple transition-colors flex flex-col justify-between">
                    <p className="mb-10">{testimonial.content}</p>
                    <div>
                    <p className="font-bold text-right justify-self-end self-end">{testimonial.author}</p>
                    <p className="text-right justify-self-end self-end">{testimonial.authorBio}</p>
                    </div>
                </div>
                </div>    
                ))}
            </Slider>
            </div>
        </div>
    </section>
        
  )
}

export default Testimonials;