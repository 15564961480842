import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ContactForm from "./contactForm"
const Download = () => {
  return (
    <section id="pobierz-ebook" className="bg-light-pink">
        <div className="max-w-7xl mx-auto grid md:grid-cols-3 gap-24">
            <div className="z-10 relative col-span-2 flex flex-col justify-end mr-24 text-lg">
                <p className="text-purple mb-12">
                Hogyan toborozzunk specialistákat és menedzsereket? <br/>
                Metaversum a toborzásban – a közelgő trend vagy a távoli jövő? <br/>
                Crowdstaffing, contracting, RPO – mennyire népszerűek ezek a toborzási szolgáltatások?
                </p> 
                <p className="text-purple font-bold mb-10">Töltsd le a ReX együttműködésével megjelent Antal felmérését, „Trendek a szakemberek és vezetők toborzásában” és tájékozódj a legfrissebb felmérési eredményekről, és szakértői észrevételekről!</p>
            </div>
            <div className="flex justify-center items-center px-12">
                <StaticImage className="" src={'../../images/Rex-mockup-klienci.png'} />
            </div>
        </div>
        <div className="max-w-7xl mx-auto">
            <ContactForm />
        </div>
        
    </section>
  )
}

export default Download